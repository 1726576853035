<template>
  <div class="project-comparison">
    <router-view
      v-if="brandId"
      :brandId="brandId"
      :filtroProject="filtroProject"
      :filtroStore="filtroStore"
    />
    <div
      class="details-no-data mt-20"
      v-else
    >
      <img
        src="@/assets/images/public/not-plandata.png"
        alt=""
      >
      <p>云端品牌库未收录此品牌，暂无数据</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    brandId: {
      type: Number
    },
    filtroProject: {
      type: Number
    },
    filtroStore: {
      type: String
    }
  },
  mounted () {
    // console.log(this.filtroProject)
  }
}
</script>

<style lang="stylus" scoped>
.project-comparison>>>
  .details-no-data
    height 300px
    overflow hidden
    margin 0
    background #272930
    padding 180px
</style>
